import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Module, RoleAttributes, RoleId } from 'sber-marketing-types/backend';
import { compact } from 'lodash';
import {
    IconType,
    NavigationMenu,
    SubmenuButton,
    InternalLink,
    InfoGroupButton,
    NavigationItemSubmenu,
    NavigationItemSubmenuItem,
} from 'sber-marketing-ui';

import { AuthApi } from '@api';

import { organisations } from '@common/constants';

import { StoreState } from '@store';
import { getLoginUser, userHasLeaderDashboardAccess, userHasPivotTableAccess } from '@store/user';

const LEADER_DASHBOARD_URLS = ['/leaderDashboard/activities', '/leaderDashboard/tasks'];
const MANAGER_DASHBOARD_URL = '/managerDashboard';
const PIVOT_TABLE_URLS = [
    '/pivotTable',
    '/pivotTable/lot1',
    '/pivotTable/lot2',
    '/pivotTable/lot1/summary',
    '/pivotTable/lot2/summary',
];
const TAGS_URL = '/tags';

const ONBOARDING_LINK = '/onboarding';
const RELEASE_NOTES_LINK = '/release-notes';

interface Props extends Partial<MapProps> {}

interface MapProps {
    userHasLeaderDashboardAccess: boolean;
    userHasPivotTableAccess: boolean;
    userModules: Module[];
    userOrganizationId: string;
    userRoles: RoleAttributes[];
}

@(withRouter as any)
@(connect(mapStateToProps) as any)
export class NavMenuContainer extends React.Component<Props> {
    public render(): JSX.Element {
        const internalItems = this.getInternalItems();
        const bottomInternalItems = this.getBottomInternalItems();

        return (
            <NavigationMenu
                env={window.process.env}
                internalItems={internalItems}
                bottomInternalItems={bottomInternalItems}
                onLogoutButtonClick={AuthApi.logout}
            />
        );
    }

    private getInternalItems(): JSX.Element[] {
        const { userOrganizationId, userHasLeaderDashboardAccess, userHasPivotTableAccess } = this.props;

        if (!userOrganizationId) {
            return [];
        }

        const leaderDashboardLink = userHasLeaderDashboardAccess ? (
            <NavigationItemSubmenuItem
                key="leaderDashboardLink"
                type="internalLink"
                title="Дашборд руководителя"
                url="/leaderDashboard/activities"
                additionalUrls={LEADER_DASHBOARD_URLS}
            />
        ) : null;
        const personalDashboardLink = this.hasAccessToInternalModule({ module: Module.Dashboard }) ? (
            <NavigationItemSubmenuItem
                key="managerDashboardLink"
                type="internalLink"
                title="Персональный дашборд"
                url={MANAGER_DASHBOARD_URL}
            />
        ) : null;
        const pivotTableLink = userHasPivotTableAccess ? (
            <NavigationItemSubmenuItem
                key="pivotTableLink"
                type="internalLink"
                title="Сводная таблица ЗНК"
                url="/pivotTable"
                additionalUrls={PIVOT_TABLE_URLS}
            />
        ) : null;
        const tagsLink = <NavigationItemSubmenuItem key="tagsLink" type="internalLink" title="Теги" url={TAGS_URL} />;

        const pivotDataItems = compact([leaderDashboardLink, personalDashboardLink, pivotTableLink, tagsLink]);
        const pivotButton = pivotDataItems.length ? (
            <SubmenuButton
                key="pivotGroup"
                qaId="pivotButton"
                title="Сводные данные"
                icon={IconType.LEADER_DASHBOARD_UNCOLORED}
                iconColor="transparent"
                iconSvgSize={24}
                activeIcon={IconType.LEADER_DASHBOARD}
                activeIconColor="transparent"
                activeIconSvgSize={24}
                theme="black"
                submenu={<NavigationItemSubmenu title="Сводные данные">{pivotDataItems}</NavigationItemSubmenu>}
                additionalUrls={[...LEADER_DASHBOARD_URLS, MANAGER_DASHBOARD_URL, ...PIVOT_TABLE_URLS, TAGS_URL]}
            />
        ) : null;

        const projectButton = this.hasAccessToInternalModule({ module: Module.Dashboard }) ? (
            <InternalLink
                key="dashboardLink"
                qaId="navigationMenuDashboardLink"
                title="Проекты"
                url="/dashboard"
                additionalUrls={[
                    '/activity/new',
                    '/activity/:id',
                    '/activity/:id/edit',
                    '/activity/:id/tasks',
                    '/activity/:id/brief',
                    '/activity/:id/creative/:id',
                    '/activity/:id/autopilot',
                    '/activity/:id/autopilot_v2',
                    '/activity/:id/autopilotTv',
                    '/activity/:id/autopilotRadio',
                    '/activity/:id/autopilotDOOH',
                    '/activity/:id/task/:id',
                    '/activity/:id/task/:id/brief',
                ]}
                icon={IconType.PROJECTS_LINK_UNCOLORED}
                iconColor="transparent"
                activeIcon={IconType.PROJECTS_LINK}
                activeIconColor="transparent"
            />
        ) : null;

        const calendarButton = this.hasAccessToInternalModule({ module: Module.Calendar }) ? (
            <InternalLink
                key="calendarLink"
                qaId="navigationMenuCalendarLink"
                title="Календарь"
                url="/calendar/chart"
                additionalUrls={['/calendar/week']}
                icon={IconType.CALENDAR_LINK}
                iconColor="#999999"
                activeIcon={IconType.CALENDAR_LINK_UNCOLORED}
                activeIconColor="#19bb4f"
            />
        ) : null;

        const budgetButton = this.hasAccessToInternalModule({ module: Module.Budget }) ? (
            <InternalLink
                key="budgetLink"
                qaId="navigationMenuBudgetLink"
                title="Бюджетирование"
                url="/budget/execution"
                additionalUrls={[
                    '/budget/planning',
                    '/budget/planning/new',
                    '/budget/planning/:id',
                    '/budget/planning/budgetItem/:budgetItemId/brief',
                    '/budget/planning/budgetItem/:budgetItemId/brief/:action(create)',
                    '/budget/execution/new',
                    '/budget/execution/:id',
                    '/budget/corrections',
                ]}
                icon={IconType.BUDGET_LINK}
                iconColor="#999999"
                activeIcon={IconType.BUDGET_LINK}
                activeIconColor="#19bb4f"
            />
        ) : null;

        const tmButton = this.hasAccessToInternalModule({
            module: Module.TM,
            role: RoleId.TMManager,
            organizations: [organisations.sberBank],
        }) ? (
            <InternalLink
                key="registryLink"
                title="Реестр ТЗ"
                url="/tm/registry"
                icon={IconType.TM}
                iconColor="#999999"
                activeIcon={IconType.TM}
                activeIconColor="#19bb4f"
            />
        ) : null;

        return compact([pivotButton, projectButton, calendarButton, budgetButton, tmButton]);
    }

    private getBottomInternalItems(): JSX.Element[] {
        return [
            <InfoGroupButton key="infoGroupButton" additionalUrls={[ONBOARDING_LINK, RELEASE_NOTES_LINK]}>
                <InternalLink
                    key="onboardlingLink"
                    withoutTooltip
                    theme="submenu"
                    title="Обучение"
                    qaId="onboardingLink"
                    url={ONBOARDING_LINK}
                    iconColor="#979797"
                    activeIconColor="#16BA4F"
                    icon={IconType.ONBOARDING}
                    activeIcon={IconType.ONBOARDING}
                />

                <InternalLink
                    key="releaseNotesLink"
                    withoutTooltip
                    theme="submenu"
                    title="История изменений"
                    qaId="navigationMenuReleaseNotesLink"
                    url={RELEASE_NOTES_LINK}
                    iconColor="transparent"
                    activeIconColor="transparent"
                    icon={IconType.RELEASE_NOTES}
                    activeIcon={IconType.RELEASE_NOTES_ACTIVE}
                />
            </InfoGroupButton>,
        ];
    }

    private hasAccessToInternalModule(args: { module: Module; organizations?: string[]; role?: RoleId }): boolean {
        const { module, organizations, role } = args;
        const { userModules, userOrganizationId, userRoles } = this.props;

        const moduleMatch = userModules.includes(module);
        const organizationMatch = organizations ? organizations.includes(userOrganizationId) : true;
        const roleMatch = role ? userRoles.some((userRole) => userRole.id === role) : true;

        return moduleMatch && organizationMatch && roleMatch;
    }
}

function mapStateToProps(state: StoreState): MapProps {
    const user = getLoginUser(state);

    let userOrganizationId = null;
    let userModules: Module[] = [];
    let userRoles: RoleAttributes[] = [];

    if (user.attributes) {
        userOrganizationId = user.attributes.organizationId;
        userModules = user.attributes.modules;
        userRoles = user.attributes.roles;
    }

    return {
        userHasLeaderDashboardAccess: userHasLeaderDashboardAccess(user),
        userHasPivotTableAccess: userHasPivotTableAccess(user),
        userOrganizationId,
        userModules,
        userRoles,
    };
}
